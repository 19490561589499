<template>
  <CHeader with-subheader class="c-header-dark">
    <CHeaderBrand class="c-header-brand-center" to="/">
      <img
        width="156"
        height="40"
        alt="Loonity Logo"
        src="./../../public/loonity/brand/LoonityLogoWhite.png"
      />
    </CHeaderBrand>
    <CHeaderNav class="mfs-auto">
      <!-- <CHeaderNavItem class="px-3">
        <button
          @click="() => $store.commit('toggle', 'darkMode')"
          class="c-header-nav-btn"
        >
          <CIcon v-if="$store.state.darkMode" name="cil-sun" />
          <CIcon v-else name="cil-moon" />
        </button>
      </CHeaderNavItem> -->
      <TheHeaderDropdownLang />
    </CHeaderNav>

    <CSubheader class="justify-content-center px-3">
      <CHeaderNav class="border-0 m-0 px-0 px-md-3">
        <CHeaderNavItem class="px-3">
          <CHeaderNavLink to="/public/login" class="coreuiOff">
            {{ $t("public.header.login") }}
          </CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem class="px-3">
          <CHeaderNavLink to="/public/registration" class="coreuiOff">
            {{ $t("public.header.register") }}
          </CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem class="px-3">
          <CHeaderNavLink to="/public/contact" class="coreuiOff">
            {{ $t("public.header.contact") }}
          </CHeaderNavLink>
        </CHeaderNavItem>
      </CHeaderNav>
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownLang from "../containers/TheHeaderDropdownLang";
export default {
  name: "Header",
  components: {
    TheHeaderDropdownLang,
  },
};
</script>

<style type="scss">
a.coreuiOff:link,
a.coreuiOff:visited,
a.coreuiOff:hover {
  color: #000 !important;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #fff !important;
  height: 56px;
}
</style>
