<template>
  <div class="c-app loonity" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CWrapper>
      <PublicHeader />
      <div class="c-body">
        <main class="c-main loonity-img-background">
          <CContainer fluid>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer>
        </main>
        <LoonityFooter />
      </div>
    </CWrapper>
  </div>
</template>

<script>
import PublicHeader from "./PublicHeader";
import LoonityFooter from "./TheFooter";

export default {
  name: "Container",
  components: {
    PublicHeader,
    LoonityFooter,
  },
};
</script>

<style type="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.loonity-img-background {
  background-image: url(./../../public/loonity/img/BannerHome18.jpg);
  background-color: #fff;
  background-size: cover;
  background-repeat: norepeat;
  background-position: bottom center;
}
</style>
